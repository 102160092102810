import styled from 'styled-components'

export const JointHighlighterContainer = styled.div`
  position: relative;
`

export const JointCirle = styled.div`
  cursor: pointer;
  background: ${(props) => (props?.swollen ? 'red' : 'transparent')};
  ${(props) => props?.printType && `background: ${props?.swollen ? '#4f525c' : '#fff'};`};
  border-radius: 50%;
  position: absolute;
  top: ${(props) => (props?.top ? `${props?.top}px` : '0')};
  left: ${(props) => (props?.left ? `${props?.left}px` : '0')};
  height: ${(props) => (props?.height ? `${props?.height}px` : '10px')};
  width: ${(props) => (props?.width ? `${props?.width}px` : '10px')};
`
