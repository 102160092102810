import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import { DefaultNextVisitRowComponent } from './DefaultBodyComponents'

export function ThreeColumnNextVisitRowComponent({
  nextVisitInputValue,
  nextVisitInputType,
  prescriptionData,
}) {
  return (
    <div className="d-flex justify-content-between gap-3">
      <DefaultNextVisitRowComponent
        nextVisitInputType={nextVisitInputType}
        nextVisitInputValue={nextVisitInputValue}
        prescriptionData={prescriptionData}
      >
        <div className="d-flex align-items-start w-100 justify-content-end">
          {prescriptionData?.prescriptionData?.qrCodeImageId && (
            <div className="d-flex flex-column align-items-center">
              <img
                src={getImageLink(prescriptionData?.prescriptionData?.qrCodeImageId)}
                alt="prescription-qr"
                height="60px"
                width="60px"
              ></img>
              <span style={{ textAlign: 'center' }}>View Online</span>
            </div>
          )}
        </div>
      </DefaultNextVisitRowComponent>
    </div>
  )
}
