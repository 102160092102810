import { GENDER_LIST_ONBOARDING } from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import { getDateOfPrescription } from '../../../PrescriptionMakingPageComponents'
import { PMPHeaderDetails } from '../../../PrescriptionMakingPageStyles'

export default function ThreeColumnPrescriptionFooterPatientDetails({
  prescriptionData,
  leaveSpace,
}) {
  return (
    <>
      <PMPHeaderDetails
        noColor={true}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          borderTop: '1px solid black',
        }}
      >
        <span>
          <strong>Patient:</strong>{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {prescriptionData?.patientPrefill?.name}{' '}
            {prescriptionData?.patientPrefill?.patientNumber
              ? `(${prescriptionData?.patientPrefill?.patientNumber})`
              : ''}{' '}
            {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
            {
              GENDER_LIST_ONBOARDING?.find(
                (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
              )?.label
            }
          </span>
        </span>
        <div style={{ textAlign: 'right' }}>
          <strong>Date :</strong> {getDateOfPrescription(prescriptionData, false)}
        </div>
      </PMPHeaderDetails>
    </>
  )
}
