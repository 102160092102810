import { openDB } from 'idb'
import {
  IDB_HEALTH_COMPLAINTS_STORENAME,
  IDB_DIAGNOSIS_STORENAME,
  IDB_DRUGS_STORENAME,
  IDB_INSTRUCTIONS_STORENAME,
  IDB_OBSERVATIONS_STORENAME,
  IDB_ADVICE_STORENAME,
  IDB_INVESTIGATION_STORENAME,
} from 'src/consumer/constants'

const requiredStores = [
  IDB_HEALTH_COMPLAINTS_STORENAME,
  IDB_DIAGNOSIS_STORENAME,
  IDB_DRUGS_STORENAME,
  IDB_INSTRUCTIONS_STORENAME,
  IDB_INVESTIGATION_STORENAME,
  IDB_OBSERVATIONS_STORENAME,
  IDB_ADVICE_STORENAME,
] // same names are their mongo collections

export async function createERMDatabase() {
  return openDB('erm-database', 6, {
    upgrade(db, oldVersion) {
      // delete the stores that are not supported
      const existingStores = Array.from(db.objectStoreNames)
      for (const store of existingStores) {
        if (!requiredStores.includes(store)) {
          db.deleteObjectStore(store) // Remove unwanted stores
        }
      }

      //you need to update the version number to update the database below
      // create stores if not already created
      if (!db.objectStoreNames.contains(IDB_HEALTH_COMPLAINTS_STORENAME)) {
        const store = db.createObjectStore(IDB_HEALTH_COMPLAINTS_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      if (!db.objectStoreNames.contains(IDB_DIAGNOSIS_STORENAME)) {
        const store = db.createObjectStore(IDB_DIAGNOSIS_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      if (!db.objectStoreNames.contains(IDB_DRUGS_STORENAME)) {
        const store = db.createObjectStore(IDB_DRUGS_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      if (!db.objectStoreNames.contains(IDB_INSTRUCTIONS_STORENAME)) {
        const store = db.createObjectStore(IDB_INSTRUCTIONS_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      if (!db.objectStoreNames.contains(IDB_INVESTIGATION_STORENAME)) {
        const store = db.createObjectStore(IDB_INVESTIGATION_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      if (!db.objectStoreNames.contains(IDB_OBSERVATIONS_STORENAME)) {
        const store = db.createObjectStore(IDB_OBSERVATIONS_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      if (!db.objectStoreNames.contains(IDB_ADVICE_STORENAME)) {
        const store = db.createObjectStore(IDB_ADVICE_STORENAME, { keyPath: 'id' })
        store.createIndex('titleIDX', 'lowerCaseIndexField', { unique: false })
      }
      // Other stores can be added similarly
    },
  })
}

export async function deleteERMDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase('erm-database')

    request.onsuccess = () => {
      console.log(`Database ${'erm-database'} deleted successfully`)
      resolve()
    }

    request.onerror = (event) => {
      console.error(`Error deleting database ${'erm-database'}:`, event.target.error)
      reject(event.target.error)
    }

    request.onblocked = () => {
      console.warn(`Database ${'erm-database'} deletion blocked`)
    }
  })
}
