import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MonitorIcon from '@mui/icons-material/Monitor'
import { allAvailableEventTypes, SCREENING_STATUSES } from 'src/consumer/constants'

export default function ScreeningCheckUI({ bookedSlot }) {
  const allPrevScreeningSteps = bookedSlot?.previousSteps?.filter(
    (step) => step?.refType === allAvailableEventTypes.screening,
  )
  const allNextScreeningSteps = bookedSlot?.nextSteps?.filter(
    (step) => step?.refType === allAvailableEventTypes.screening,
  )

  function handleScreeningStatus(screeningStep, title) {
    switch (screeningStep?.status) {
      case SCREENING_STATUSES.COMPLETED:
        return (
          <span>
            {title}: <CheckCircleIcon style={{ color: '#E5CCE7', fontSize: '16px' }} />
          </span>
        )
      case SCREENING_STATUSES.NOT_STARTED:
        return (
          <span>
            {title}:
            <CancelIcon style={{ color: 'red', fontSize: '16px' }} />
          </span>
        )
      case SCREENING_STATUSES.STARTED:
      default:
        return (
          <span>
            {title}: <MonitorIcon style={{ color: 'yellow', fontSize: '16px' }} />
          </span>
        )
    }
  }

  return allPrevScreeningSteps?.length > 0 ? (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {allPrevScreeningSteps?.map((screeningStep) => {
        return handleScreeningStatus(screeningStep, `Screening (Pre-Rx) (by ${screeningStep?.stepHandlerName})`)
      })}
      {allNextScreeningSteps?.map((screeningStep) => {
        return handleScreeningStatus(screeningStep, `Screening(Post-Rx) `)
      })}
    </div>
  ) : (
    <></>
  )
}
